/* You can add global styles to this file, and also import other style files */
$primary-color: #1181b2
$forma-blue: #1E9CB9
$secondary-color: #212221

$accent-color-500: #ddedf4
$accent-color-600: #f2e6d9

$primary-text-color: #D2EBF1
$unawa-orange: #FF9F00
$signsecure-orange: #F89E1B
$forma-blue: #1E9CB9

$black: #4b4b4b
$dms-black: #262E37

$gradient: linear-gradient(180deg, #F4FBFF 0%, #DBE8F9 100%)

$gray-color-100: #ffffff
$gray-color-200: #f5f5f5
$gray-color-300: #ebebeb
$gray-color-400: #cccccc
$gray-color-500: #a3a3a3
$gray-color-600: #8f8f8f
$gray-color-700: #7a7a7a
$gray-color-800: #666666
$gray-color-900: #525252

button
    cursor: pointer

.mat-progress-spinner circle, .mat-spinner circle
    stroke: var(--primary-color, $primary-color) !important

// .mat-checkbox-checked .mat-checkbox-checkmark-path
//     stroke: var(--primary-color) !important

.mat-checkbox-checked.mat-accent .mat-checkbox-background
    background-color: var(--primary-color) !important
//    border: 2px solid var(--primary-color)
    border-radius: 5px
.mat-checkbox-frame
    border-color: var(--primary-color)
    border-radius: 5px !important
    // @include for-size(phone-only)     
    //     font-size: 1rem
//SafeForm Template styles
.twMainContent
    padding: 4px 48px 48px
    font-family: 'Calibri', sans-serif
.twFooter
    text-align: center
    padding: 40px
.twParagraphLabel
    font-size: 14px
    font-family: 'Calibri', sans-serif
    color: gray
    margin-bottom: 0 !important

.twHeaderTitle
    font-family: 'Calibri', sans-serif
    font-size: 20px
    text-align: center
    margin: 16px 0 -4px
.mt-3
    margin-top: 1rem
.twHeader
    padding: 32px 48px 0
.table100
    width: 100%
.twHeaderImg
    width: 30%
.twPIParagraphLabel
    font-size: 12px
    font-family: 'Calibri', sans-serif
    color: gray
    margin-bottom: 0
.twContentPadding
    padding: 1rem 2.4rem 2.4rem
.twtableBorder
    border: 1px solid gray
.twtableBG
    background-color: gray
.twFontWhite
    color: white !important
.tableAlignTop
    vertical-align: top
.table20
    width: 20%
.tableBorder
    border-collapse: collapse
    border: 1px solid #000000
.twSetHeight
    height: 100px
.twListMarginTop
    margin: -4px 16px 8px !important
.text-right
    text-align: right !important
.twtableBorderLeft
    border-left: 1px solid gray
.piSigUnderline
    border-top: 1px solid gray
    width: 70%
    margin-left: auto
    margin-right: auto
.table50
    width: 50%
.twBorderBottom
    border-bottom: 1px solid gray
.text-center
    text-align: center
.twTDHeight
    height: 80px
.table40
    width: 40%
.table15
    width: 15%
.table25
    width: 25%
.table30
    width: 30%
.table35
    width: 35%
.table60
    width: 60%
.table75
    width: 75%
.tableBorderNone
    border-collapse: collapse
    border: none
.twFooterText
    font-size: 16px
    font-family: 'Calibri', sans-serif
    color: #000
.mr-5
    margin-right: 3rem
.twFooterImage
    width: 20% !important

.twRequestorDiv
    position: absolute
    margin-top: -21px
    margin-left: 140px
    width: 35%
.twControlNumberDiv
    position: absolute
    margin-top: -21px
    margin-left: 101px
    width: 20%
.twPayeeDiv
    position: absolute
    margin-top: -21px
    margin-left: 42px
    width: 48%
.twRequestDateDiv
    position: absolute
    margin-top: -21px
    margin-left: 85px
    width: 20%
.twTotalMarginRight
    margin-right: 16px
.mt-4
    margin-top: 1.5rem
.twTableMarginTop
    margin-top: -16px
.twDueDateDiv
    position: absolute
    margin-top: -21px
    margin-left: 61px
    width: 23%

.twModeOfPaymentDiv
    position: absolute
    margin-top: -21px
    margin-left: 110px
    width: 25%

.twUrgentDiv
    position: absolute
    margin-top: -21px
    margin-left: 47px
    width: 10%

.tableAlignBottom
    vertical-align: bottom
.piDivUnderline6
    border-bottom: 1px solid gray
    width: 81%
    float: right
    @media (min-width:1920px)
        width: 85%

.piDivUnderline7
    border-bottom: 1px solid gray
    width: 84%
    float: right
    @media (min-width:1920px)
        width: 88%

.piDivUnderline8
    border-bottom: 1px solid gray
    width: 60%
    float: right
    @media (min-width:1920px)
        width: 67%

.piDivUnderline9
    border-bottom: 1px solid gray
    width: 62%
    float: right
    @media (min-width:1920px)
        width: 70%

.piDivUnderline10
    border-bottom: 1px solid gray
    width: 74%
    float: right
    @media (min-width:1920px)
        width: 80%
.table65
    width: 65%
    p
        margin-top: 14px

/*** TO USE MIXIN **
// Import this sass
// to call @include for-size(phone-only) {    font-size: 1rem; }
// must be inside a parent class
// Example
// .header 
//      font-size: 2rem
//      
@mixin for-size($size)
    @if $size == phone-only
        @media (max-width: 600px)
            @content
    @else if $size == tablet-portrait-up
        @media (min-width: 600px) and (max-width: 1368px)
            @content
    @else if $size == tablet-landscape-up
        @media (min-width: 912px)
            @content
    @else if $size == desktop-up
        @media (min-width: 1200px)
            @content
    @else if $size == desktop-only
        @media (min-width: 1080px) and (max-width: 1919px)
            @content
    @else if $size == big-desktop-up
        @media (min-width: 1920px)
            @content
    @else if $size == mac-default
        @media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)
            @content

@function addOpacity($color, $opacity)
    @return $color + $opacity

.margin-container
    padding-left: 104px
    padding-right: 90px

@include for-size('desktop-only')
    .margin-container
        padding-left: 93.6px
        padding-right: 81px

::ngdeep .mat-form-field-appearance-fill .mat-form-field-flex
    padding: 0 0 0 0
    border-top: 0
::ng-deep .mat-form-field-wrapper
    padding-bottom: 0
    .mat-form-field-flex
        padding: 0 10px 0 10px
        .mat-form-field-infix
            padding: 0 0 0 0
            border-top: 0
::ng-deep .mat-tooltip
    background: var(--primary-color, $forma-blue) !important


.esdcHeaderImg 
    width: 100%

.esdcMainContent
    padding: 32px

.esdcHeaderTitle
    text-align: center
    font-size: 20px
    font-family: 'Tahoma', sans-serif
    font-weight: bold
    padding-bottom: 16px

.esdcLine
    border-top: 1px solid #000

.esdcParagraph
    font-size: 12px
    font-family: 'Tahoma', sans-serif
    padding: 16px 0
    margin: 0
    text-align: justify

.esdcTable
    width: 100%

.esdcTdColumnOne,
.esdcSubColumnOne
    width: 5%
    font-weight: bold

.esdcTdTitle 
    font-size: 13px
    font-family: 'Tahoma', sans-serif
    font-weight: bold
    margin-bottom: 0
    padding: 0
    vertical-align: top

.esdcColumn-95 
    width: 95%
    vertical-align: top

.esdcTdBottomLine 
    border-bottom: 1px solid #000

.esdcSubTable
    margin-bottom: 16px

.esdcTdParagraph
    font-size: 10px
    font-family: 'Tahoma', sans-serif
    padding: 16px 0
    margin: 0
    padding: 4px 0
    vertical-align: top
    text-align: justify

.esdcContentTable
    margin-top: 8px

.esdcContentColumnOne
    width: 20%
    font-weight: bold

.esdcColumn-50
    width: 50%
    vertical-align: top

.esdcPaddingRight
    padding-right: 16px

.esdcColumn-10
    width: 10%
    font-weight: bold

.esdcPaddingLeft
    padding-left: 16px

.esdcMarginTop
    margin-top: 0px

.formPages
    margin-top: 64px

.esdcSigAlignCenter
    text-align: center

.esdcSigText
    font-size: 12px
    font-family: 'Tahoma', sans-serif
    font-weight: bold
    margin-bottom: 0
    padding: 0
    vertical-align: top

.esdcSigPaddingLeft
    padding-left: 40px !important
    text-transform: uppercase

.col
    text-align: right

.signature-div
    .img-signature-div
        bottom: -85px
        left: 130px
        text-align: center
    
    img
        width: 150px
        height: 60px

.iaPageOneHeaderImg
    width: 102%
    margin: -10px -10px 0

.iaPageOneContent
    padding: 32px

.iaPageOneTitle
    text-align: center
    font-size: 20px
    font-family: 'Tahoma', sans-serif
    font-weight: bold
    padding-bottom: 16px

.isTopLine
    border-top: 1px solid #000
    padding: 16px 32px 16px 0

.iaLeftMargin
    margin-left: 48px

.iaParagraph
    font-size: 11px
    font-family: 'Tahoma', sans-serif
    margin: 0
    text-align: justify
    vertical-align: top

.iaPaddingBottom16 
    padding-bottom: 16px

.iaAlignCenter
    text-align: center

.iaPaddingTB
    padding: 8px 0

.iaUpperCaseOl
    list-style-type: upper-alpha
    font-size: 10px
    font-family: 'Tahoma', sans-serif
    margin-bottom: 0

.iaTable100
    width: 100%

.iaTdColumn5
    width: 5%
    font-weight: bold
    vertical-align: top

.iaTdTitle
    font-size: 13px
    font-family: 'Tahoma', sans-serif
    font-weight: bold
    margin-bottom: 0
    padding: 0
    vertical-align: top

.iaTdColumn95
    width: 95%
    vertical-align: top

.iaBottomLine
    width: 60%
    border-bottom: 1px solid #a1a0a0
    text-align: center
    margin: 6px 0

.iaPaddingBottom6
    padding-bottom: 6px

.iaTdColumn50
    width: 50%
    vertical-align: top

.iaPaddingRight
    padding-right: 16px

.iaTdColumn10
    width: 10%
    vertical-align: top

.iaBottomMargin16
    margin-bottom: 16px

.esdcSpanFont
    font-size: 11px
    font-family: 'Tahoma', sans-serif !important

.esdcHideData
    display: none
    font-size: 8px
    font-family: 'Tahoma', sans-serif !important

.iaPage
    margin-top: 32px


// for ESDC CSS
.page-breaker
    margin-top: 240px

// for R1 - Invertor Agreement
.ia-page-breaker
    margin-top: 80px
.ia-page-breaker2
    margin-top: 230px
.ia-page-breaker3
    margin-top: 215px
.ia-page-breaker4
    margin-top: 180px

// for R1 - Issuer Agreement
.a-page-breaker1
    margin-top: 190px
.a-page-breaker2
    margin-top: 275px
.a-page-breaker3
    margin-top: 240px
.a-page-breaker4
    margin-top: 165px
.a-page-breaker5
    margin-top: 60px
.a-page-breaker6
    margin-top: 100px
.a-page-breaker7
    margin-top: 230px
.a-page-breaker8
    margin-top: 145px
.a-page-breaker9
    margin-top: 225px